
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../../style/font-family.scss';
@import '../../../style/buttons.scss';
.cal-icon {
    margin-right: 6px;
	width: 12px;
}
.globe-icon {
    margin-left: 6px;
    z-index: 99;
    position: relative;
}
